import { Breakpoint, ConfigProvider, Grid, theme } from "antd";
import locale from "antd/locale/et_EE";
import dayjs from "dayjs";
import "dayjs/locale/et";
import updateLocale from "dayjs/plugin/updateLocale";
import { createContext } from "react";

import AntdMobileThemeProvider from "./antdMobileThemeProvider";
import {
  darkThemeComponents,
  darkThemeToken,
  lightThemeComponents,
  lightThemeToken,
} from "./antdTheme";

dayjs.extend(updateLocale);

dayjs.updateLocale("et", {
  weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
});

type LayoutContextProps = {
  breakpoint: Partial<Record<Breakpoint, boolean>>;
  isDarkTheme: boolean;
};

export const ThemeContext = createContext<LayoutContextProps>({
  breakpoint: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  },
  isDarkTheme: false,
});

type ThemeProviderProps = { children: React.ReactNode; isDarkTheme?: boolean };

const ThemeProvider = ({
  children,
  isDarkTheme = false,
}: ThemeProviderProps) => {
  const breakpoint = Grid.useBreakpoint();

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        algorithm: isDarkTheme ? theme.darkAlgorithm : theme.defaultAlgorithm,
        components: isDarkTheme ? darkThemeComponents : lightThemeComponents,
        token: isDarkTheme ? darkThemeToken : lightThemeToken,
      }}
    >
      <AntdMobileThemeProvider />

      <ThemeContext.Provider value={{ breakpoint, isDarkTheme }}>
        {children}
      </ThemeContext.Provider>
    </ConfigProvider>
  );
};

export default ThemeProvider;
